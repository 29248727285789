// import clonedeep from "./node_modules/lodash-es/cloneDeep.js";
import cloneDeep from 'lodash-es';

('use strict');

///////////////////////////////////////////////////////
// THINGS TO CHANGE DURING DEVELOPMENT
///////////////////////////////////////////////////////
//  //IN SCRIPT>JS FOR SERVERURL
//      var SERVERURL = 'http://localhost:3000'; - USE FOR TESTING SERVER CODE
//        OR
//      var SERVERURL = 'https://safe-journey-35449.herokuapp.com'; - USE FOR RELEASE

//  //IN GUESTBOOK.JS CLIENT URL
//      url: 'http://127.0.0.1:3000/api/v1/guestbooks', - USE FOR TESTING SERFER CODE
//        OR;
//      url: 'https://safe-journey-35449.herokuapp.com', - USE FOR RELEASE
///////////////////////////////////////////////////////

///////////////////////////////////////////////////////
// GLOBAL FUNCTIONS DEFINED BELOW
// window.loadBufferImage = loadBufferImage;
//window.patchTrip = patchTrip;
///////////////////////////////////////////////////////

///////////////////////////////////////////////////////
// STANDARD RECIPE ITEMS FOR PACKING CODE FOR OLDER BROWSERS
//Does not trigger a re-load when saving
if (module.hot) {
  module.hot.accept();
}

// Polyfill for code translation if needed
import 'core-js/stable';

// Polyfill async functions
import 'regenerator-runtime/runtime';
import { divIcon } from 'leaflet';

// Add this right after the imports
const isMobile = window.matchMedia('(max-width: 768px)').matches;

// For perfming API requests
const axios = require('axios'); //.default;

// Code for location mapping
let myMap = L.map('map').setView([0, 0], 13);

L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
  attribution:
    '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
}).addTo(myMap);

let imagePath = '';

const SMALLIMAGERESOLUTION = isMobile ? 150 : 300;
const LARGEIMAGERESOLUIION = isMobile ? 450 : 600;

//var SERVERURL = 'http://localhost:3000';
var SERVERURL = 'https://safe-journey-35449.herokuapp.com';

//Global flag
var guestbookShown = false;

// Global array of trips information from Trips database
window.tripsList = 'I am global';

// Prevent scrolling of the accordion and modal content
document.addEventListener('DOMContentLoaded', function () {
  document.body.addEventListener(
    'touchmove',
    function (e) {
      // Detect horizontal scrolling
      console.log('================1====================');
      const touchDelta = e.touches[0].clientX - e.touches[0].screenX;
      console.log('Touch move delta:', touchDelta);

      let photoContainer = e.target.closest('.photo-container');
      let modalContent = e.target.closest('.modal-content');
      let item = e.target.closest('.item');
      let year = e.target.closest('.year');
      let accordion = e.target.closest('.accordion');
      let accordionManager = e.target.closest('.accordionanager');
      let container = e.target.closest('.container');

      console.log('photoContainer:', photoContainer);
      console.log('modalContent:', modalContent);
      console.log('item:', item);
      console.log('year:', year);
      console.log('accordion:', accordion);
      console.log('accordionManager:', accordionManager);
      console.log('container:', container);

      // Only prevent horizontal movement
      if (
        Math.abs(touchDelta) > 0 &&
        //!e.target.closest('.photo-container, .modal-content')
        !e.target.closest('.accordion')
      ) {
        e.preventDefault();
        console.log('Prevented touchmove');
      }
    },
    { passive: false },
  );

  // Prevent horizontal scroll on accordion items themselves
  const accordion = document.querySelector('.accordion');
  accordion.addEventListener(
    'touchmove',
    function (e) {
      console.log('=========2=============');
      const touchDelta = e.touches[0].clientX - e.touches[0].screenX;
      console.log('Touch move delta on accordionX:', touchDelta);

      const touchDeltaY = e.touches[0].clientY - e.touches[0].screenY;
      console.log('Touch move delta on accordionY:', touchDeltaY);

      let photoContainer = e.target.closest('.photo-container');
      let modalContent = e.target.closest('.modal-content');
      let item = e.target.closest('.item');
      let year = e.target.closest('.year');
      let accordion = e.target.closest('.accordion');
      let accordionManager = e.target.closest('.accordionManager');
      let container = e.target.closest('.container');

      console.log('photoContainer:', photoContainer);
      console.log('modalContent:', modalContent);
      console.log('item:', item);
      console.log('year:', year);
      console.log('accordion:', accordion);
      console.log('accordionManager:', accordionManager);
      console.log('container:', container);

      if (
        !e.target.closest('.container') &&
        !e.target.closest('.photo-container')
      ) {
        console.log('Touch move on accordion item');
        //const touchDelta = e.touches[0].clientX - e.touches[0].screenX;
        //console.log('Touch move delta xx accordion:', touchDelta);

        if (Math.abs(touchDelta) > 0) {
          e.preventDefault();
          console.log('Prevented touchmove on accordion item');
        }
      }
    },
    { passive: false },
  );

  // Allow vertical scrolling
  document.documentElement.style.overflowY = 'auto';
  document.body.style.overflowY = 'auto';
  // Prevent horizontal scrolling
  document.documentElement.style.overflowX = 'hidden';
  document.body.style.overflowX = 'hidden';

  // Add debug logging for touches
  console.log('Debug: Setting up touch handlers');
});

/////////////////////////////////////////////////////
// Code for Accordian operation
/////////////////////////////////////////////////////

/*Handle mouse clicks on LEFT image scroll buttons*/
//Handlers are initialized after trips are loaded
function wireScrollLeftClick(item, tripsIndex) {
  let index = tripsIndex;
  item.addEventListener('click', function (e) {
    let photoDat = window.tripsList[index];
    console.log('Click Hidden Left');
    const hiddenBoxParent = item.parentNode;
    const allImages = hiddenBoxParent.querySelectorAll('img', '.photo');

    // console.log('photoDat - onRtClick=', photoDat);
    // console.log('window.tripsList=', window.tripsList);

    const nItems = photoDat.files.length;
    //Scroll images to the right by moving index to left
    if (photoDat.index > 0) {
      photoDat.index = photoDat.index - 1;
    }

    setHTMLImgBuffers(allImages, photoDat.photoBufferLocal, photoDat.index);

    //Hide/Showscroll button
    const rightButton = hiddenBoxParent.getElementsByClassName('btn--right');
    const leftButton = hiddenBoxParent.getElementsByClassName('btn--left');
    adjustScrollBtnVisability(leftButton, rightButton, photoDat.index, nItems);

    //console.log('Click Left');
  });
}

/* Handle mouse click on RIGHT image scroll button*/
function wireScrollRtClick(item, tripsIndex) {
  let index = tripsIndex;
  item.addEventListener('click', function (e) {
    let photoDat = window.tripsList[index];
    console.log('Click Hidden Right');
    const hiddenBoxParent = item.parentNode;
    const allImages = hiddenBoxParent.querySelectorAll('img', '.photo');

    //console.log('photoDat - index=', index);
    //console.log('photoDat - onRtClick=', photoDat);
    // console.log('window.tripsList=', window.tripsList);

    const nItems = photoDat.files.length;
    //console.log('nItems=', nItems);
    // console.log('photoDat.index=', photoDat.index);

    //Scroll images to the left by moving index to the right
    if (photoDat.index <= nItems - 3) {
      photoDat.index = photoDat.index + 1;
    }

    // See if we need to fetch another image
    if (photoDat.photoBufferLocal[photoDat.index + 2] === 0) {
      spinner.style.display = 'block';
      Promise.all([
        loadBufferImage(
          photoDat.photoId[photoDat.index + 2],
          photoDat,
          photoDat.index + 2,
          SMALLIMAGERESOLUTION,
        ),
      ]).then(function () {
        //console.log('additional image promise finished');

        setHTMLImgBuffers(allImages, photoDat.photoBufferLocal, photoDat.index);

        spinner.style.display = 'none';
      });
    } else {
      setHTMLImgBuffers(allImages, photoDat.photoBufferLocal, photoDat.index);
    }

    //Hide/Show scroll button
    const rightButton = hiddenBoxParent.getElementsByClassName('btn--right');
    const leftButton = hiddenBoxParent.getElementsByClassName('btn--left');
    adjustScrollBtnVisability(leftButton, rightButton, photoDat.index, nItems);

    console.log('Click RightA');
    //console.log('guestbookShown=', guestbookShown);
    //Dont show guestbook if small screen
    const mediaQuery = window.matchMedia('(max-width: 600px)');
    console.log('mediaQuery=', mediaQuery);
    if (guestbookShown === false && !mediaQuery.matches) {
      const guestBook = document.getElementById('guestBook');
      guestBook.style.display = 'block';
      guestbookShown = true;
    }
  });
}

function adjustScrollBtnVisability(
  leftButton,
  rightButton,
  startIndex,
  nItems,
) {
  // console.log('adjustScrollBtnVisability');
  // console.log('leftButton', leftButton);
  // console.log('rightButton', rightButton);
  // console.log('startIndex', startIndex);
  // console.log('nItems', nItems);

  //Turn both on
  rightButton[0].setAttribute('class', 'btn btn--right');
  leftButton[0].setAttribute('class', 'btn btn--left');

  if (startIndex === 0) {
    leftButton[0].setAttribute('class', 'hide btn btn--left');
  }

  if (startIndex >= nItems - 3) {
    rightButton[0].setAttribute('class', 'hide btn btn--right');
  }
}

// Assign image buffers to trip html elements
function setHTMLImgBuffers(htmlElements, photoBufferLocal, startIndex) {
  // console.log('htmlElements=', htmlElements);
  // console.log('photoBufferLocal=', photoBufferLocal);
  // console.log('startIndex=', startIndex);
  //imagePath = tripsFolderPath + '/' + photoDat.name;
  htmlElements[0].setAttribute(
    'src',
    //imagePath + '/' + photoDat.files[photoDat.index]
    `data:image/jpeg;base64,${photoBufferLocal[startIndex]}`,
  );
  htmlElements[1].setAttribute(
    'src',
    //imagePath + '/' + photoDat.files[photoDat.index + 1]
    `data:image/jpeg;base64,${photoBufferLocal[startIndex + 1]}`,
  );
  htmlElements[2].setAttribute(
    'src',
    //imagePath + '/' + photoDat.files[photoDat.index + 2]
    `data:image/jpeg;base64,${photoBufferLocal[startIndex + 2]}`,
  );

  // Keep track of trip and index in "alt"
  let tripNum = getTripNumber(htmlElements[0].getAttribute('alt'));
  htmlElements[0].setAttribute('alt', `${tripNum},${startIndex}`);
  htmlElements[1].setAttribute('alt', `${tripNum},${startIndex + 1}`);
  htmlElements[2].setAttribute('alt', `${tripNum},${startIndex + 2}`);
}

//Set up event listeners for Open/Close buttons
function wireOpenCloseBtn(item, index) {
  //console.log('item=', item);
  item.addEventListener('click', function (e) {
    //Get parent of SVG element (<div class="item")
    const svg_parent = item.parentNode;
    //console.log('svg_parent=', svg_parent);

    //Get list of all button children of parent
    //const all_btns = svg_parent.querySelectorAll('.btn');
    //console.log('all_btns=', all_btns);

    const rightButton = svg_parent.getElementsByClassName('btn--right');
    //console.log('rightButton=', rightButton);

    const leftButton = svg_parent.getElementsByClassName('btn--left');
    //console.log('leftButton=', leftButton);

    const allImgs = svg_parent.querySelectorAll('.photo');
    //console.log('allImgs=', allImgs);

    const photoAlt = allImgs[0].alt;
    const tripNumber = getTripNumber(photoAlt);

    const openTrip = window.tripsList[tripNumber];
    //console.log('openTrip=', openTrip);

    const allItems = document.querySelectorAll('.item');
    //console.log('allItems=', allItems);
    //console.log('index=', index);

    const allItemsIcons = document.querySelectorAll('.icon');
    //Console.log('allItemsIcons=', allItemsIcons);

    //Toggle icon from up to down or vice versa
    // up arrow d="M5 15l7-7 7 7"  - open
    // dn arrow d="M19 9l-7 7-7-7"
    //Get SVG element - PATH element
    const childPath = item.firstElementChild;
    //console.log('childPath=', childPath);
    if (childPath.getAttributeNS(null, 'd') === 'M19 9l-7 7-7-7') {
      //Icon is down, change to up, Open accordion section
      //Make sure all othe accordians are closed and down
      for (let i = 0; i < allItems.length; i++) {
        allItems[i].setAttribute('class', 'item');
        allItemsIcons[i].firstElementChild.setAttribute('d', 'M19 9l-7 7-7-7');
      }

      childPath.setAttribute('d', 'M5 15l7-7 7 7');
      svg_parent.setAttribute('class', 'item open');

      // Turn on scrool buttons
      adjustScrollBtnVisability(
        leftButton,
        rightButton,
        openTrip.index,
        openTrip.files.length,
      );
    } else {
      //Icon is up, change to down, Close accordion section
      childPath.setAttribute('d', 'M19 9l-7 7-7-7');
      svg_parent.setAttribute('class', 'item');

      //Turn off scroll buttons
      rightButton[0].setAttribute('class', 'hide btn btn--right');
      leftButton[0].setAttribute('class', 'hide btn btn--left');
    }

    /* remove modal window and it's action buttons*/
    modal.style.display = 'none';
    noteInput.style.display = 'none';
    saveButton.style.display = 'none';
  });
}

// Return an array  that list dates change - Use year of second photo
// [year, tripNumber]
function getYearGroupInfo(trips) {
  const groupInfo = [];
  const groupItemInfo = [];
  let lastNumber = 3000;
  trips.forEach((trips, index) => {
    const fourChars = trips.dates[1].slice(0, 5);
    const number = parseInt(fourChars, 10);

    if (number < lastNumber) {
      groupInfo.push([number, index]);
      lastNumber = number;
    }
  });
  //console.log('groupInfo=', groupInfo);
  return groupInfo;
}

function loadTripsYear(itemYear) {
  // Get parent of SVG element (<div class="year")
  const svg_parent = itemYear.parentNode;
  //console.log('svg_parent=', svg_parent);
  const itemParentNodeID = svg_parent.id;
  //console.log('itemParentNodeID=', itemParentNodeID);

  // Add elemet to array for last year [1000, trips.length]
  let groupInfo = getYearGroupInfo(window.tripsList);
  groupInfo.push([10000, window.tripsList.length]);
  //console.log('groupInfo=', groupInfo);

  //Find index of year that was clicked
  const index = groupInfo.findIndex(
    (pair) => pair[0] === parseInt(itemParentNodeID, 10),
  );
  //console.log('index=', index);

  // Determine range of trips in clicked year
  const first = groupInfo[index][1];
  const last = groupInfo[index + 1][1] - 1;
  // Load em up
  const yearGroup = groupInfo[index][0];

  loadTripsAndPhotos(window.tripsList, first, last, yearGroup);
}

function unloadTripsYear(tripYear) {
  //console.log('tripYear=', tripYear);
  //console.log('tripYear.nextSibling', tripYear.nextSibling);
  while (tripYear.nextSibling && tripYear.nextSibling.className != 'year') {
    tripYear.nextSibling.remove();
  }
}

//Set up event listeners for year Open (^) buttons
function wireOpenBtnYear(item, index) {
  item.addEventListener('click', function (e) {
    const childPath = item.firstElementChild;
    //console.log('childPath=', childPath);

    if (childPath.getAttributeNS(null, 'd') === 'M19 9l-7 7-7-7') {
      //Icon is down, change to up, load trips of year
      childPath.setAttribute('d', 'M5 15l7-7 7 7');
      loadTripsYear(item);
    } else {
      //Icon is up, change to down, Close accordion section
      childPath.setAttribute('d', 'M19 9l-7 7-7-7');
      unloadTripsYear(item.parentNode);
    }

    /* remove modal window and it's action buttons*/
    modal.style.display = 'none';
    noteInput.style.display = 'none';
    saveButton.style.display = 'none';
  });
}
/////////////////////////////////////////////////////
/*Code for modal window with enlarged image*/
/////////////////////////////////////////////////////
const modal = document.getElementById('myModal');
const modalImg = document.getElementById('img01');
const noteInput = document.getElementById('myInput');
const photoImgs = document.querySelectorAll('.photo');
const saveButton = document.getElementsByClassName('btn-saveNote')[0];
const noteText = document.getElementsByClassName('imageNoteText')[0];
const modalContainer = document.getElementsByClassName('modalContainer')[0];
const spinner = document.getElementById('spinner');

// initialize touch handlers for modal
wireModalTouch();

/*Set up Event handlers for clickinng on an image*/
photoImgs.forEach(wireImageClick);
function wireImageClick(item, index) {
  item.addEventListener('click', function (e) {
    modalImg.src = this.src;
    modalImg.alt = this.alt;

    console.log('*****IMAGE CLICK****');

    photoImgs.forEach((item) => {
      wireImageClick(item);
      wireImageTouch(item); // Add this line
    });

    //Hide the buttons
    //Get parent node of scroll buttons
    const photoContainer = item.parentNode;
    //console.log('photoContainer=', photoContainer);

    const hiddenBox = photoContainer.parentNode;
    //console.log('hiddenBox=', hiddenBox);

    // Get the trip data for selected trip
    const trip = window.tripsList[getTripNumber(modalImg.alt)];
    // Get index of displayed photo and use to get rest of information
    const file_index = getTmageIndex(modalImg.alt);

    updateModalInfo(modal, modalImg, trip, file_index);

    //Hide the hiddenBox right scroll button
    const rightButton = hiddenBox.getElementsByClassName('btn--right');
    const leftButton = hiddenBox.getElementsByClassName('btn--left');

    //Hide both buttons
    rightButton[0].setAttribute('class', 'hide btn btn--right');
    leftButton[0].setAttribute('class', 'hide btn btn--left');
  });
}

function wireImageTouch(item) {
  let startX = 0;
  let moved = false;

  item.addEventListener('touchstart', (e) => {
    startX = e.touches[0].clientX;
    moved = false;
  });

  item.addEventListener('touchmove', (e) => {
    moved = true;
    const diff = startX - e.touches[0].clientX;
    if (Math.abs(diff) > 50) {
      e.preventDefault();
    }
  });

  item.addEventListener('touchend', (e) => {
    if (!moved) {
      wireImageClick(item);
      return;
    }
    const diff = startX - e.changedTouches[0].clientX;
    if (diff > 50) {
      const rightBtn = item.parentNode.querySelector('.btn--right');
      if (rightBtn) rightBtn.click();
    } else if (diff < -50) {
      const leftBtn = item.parentNode.querySelector('.btn--left');
      if (leftBtn) leftBtn.click();
    }
  });
}

//Set up event listeners for scroll left  buttons
const scrollLeftBtns = document.querySelectorAll('.modalBtn--left');
wireScrollModalLeftClick(scrollLeftBtns[scrollLeftBtns.length - 1]);

//Set up event listeners for scroll right  buttons
const scrollRightBtns = document.querySelectorAll('.modalBtn--right');
wireScrolllModalRtClick(scrollRightBtns[scrollRightBtns.length - 1]);

// Modal touch events
function wireModalTouch() {
  const modalContent = document.getElementById('img01');
  let startX = 0;
  let moved = false;

  modalContent.addEventListener('touchstart', (e) => {
    startX = e.touches[0].clientX;
    moved = false;
  });

  modalContent.addEventListener('touchmove', (e) => {
    moved = true;
    const diff = startX - e.touches[0].clientX;
    if (Math.abs(diff) > 50) {
      // Prevent scrolling when swiping
      e.preventDefault();
    }
  });

  modalContent.addEventListener('touchend', (e) => {
    if (!moved) {
      return; // Was just a tap, not a swipe
    }

    const diff = startX - e.changedTouches[0].clientX;
    if (diff > 50) {
      // Swipe left - go to next image
      const rightBtns = document.querySelectorAll('.modalBtn--right');
      const rightBtn = rightBtns[rightBtns.length - 1];
      if (rightBtn && !rightBtn.classList.contains('hide')) {
        rightBtn.click();
      }
    } else if (diff < -50) {
      // Swipe right - go to previous image
      const leftBtns = document.querySelectorAll('.modalBtn--left');
      const leftBtn = leftBtns[leftBtns.length - 1];
      if (leftBtn && !leftBtn.classList.contains('hide')) {
        leftBtn.click();
      }
    }
  });
}

//Load photo to left of current one, unlsess at end of list
function wireScrollModalLeftClick(item) {
  item.addEventListener('click', function (e) {
    console.log('Click Modal Left');
    const tripNumber = getTripNumber(modalImg.alt);
    const trip = window.tripsList[tripNumber];
    //console.log('trip=', trip);

    //console.log('modalImg.alt1=', modalImg.alt);
    const file_index = getTmageIndex(modalImg.alt);
    //console.log('file_index=', file_index);

    const numPhotos = trip.dates.length;
    //console.log('numPhotos=', numPhotos);

    if (file_index > 0) {
      const newFileIndex = parseInt(file_index) - 1;

      modalContainer.style.display = 'none';
      updateModalInfo(modal, modalImg, trip, newFileIndex);
      modalContainer.style.display = 'block';

      const newAlt = `${tripNumber},${newFileIndex}`;
      //console.log('newAlt=', newAlt);
      modalImg.alt = newAlt;
    }
  });
}
//Load photo to right of current one, unlsess at end of list
function wireScrolllModalRtClick(item) {
  item.addEventListener('click', function (e) {
    console.log('Click Modal Right');
    const tripNumber = getTripNumber(modalImg.alt);
    const trip = window.tripsList[tripNumber];
    //console.log('trip=', trip);

    //console.log('modalImg.alt1=', modalImg.alt);
    const file_index = getTmageIndex(modalImg.alt);
    //console.log('file_index=', file_index);

    const numPhotos = trip.dates.length;
    //console.log('numPhotos=', numPhotos);

    if (file_index < numPhotos - 1) {
      const newFileIndex = parseInt(file_index) + 1;

      modalImg.style.display = 'none';
      updateModalInfo(modal, modalImg, trip, newFileIndex);
      modalImg.style.display = 'block';

      const newAlt = `${tripNumber},${newFileIndex}`;
      //console.log('newAlt=', newAlt);
      modalImg.alt = newAlt;
    }

    //Dont show guestbook if small screen
    const mediaQuery = window.matchMedia('(max-width: 600px)');
    if (guestbookShown === false && !mediaQuery.matches) {
      const guestBook = document.getElementById('guestBook');
      guestBook.style.display = 'inline-block';
      guestbookShown = true;
    }
  });
}

function adjustScrollModalBtnVisability(
  leftButton,
  rightButton,
  startIndex,
  nItems,
) {
  //console.log('adjustScrollModalBtnVisability');
  // console.log('leftButton', leftButton);
  // console.log('rightButton', rightButton);
  // console.log('startIndex', startIndex);
  // console.log('nItems', nItems);

  //Turn both on
  rightButton[0].setAttribute('class', 'btn modalBtn--right');
  leftButton[0].setAttribute('class', 'btn modalBtn--left');

  if (startIndex === 0) {
    leftButton[0].setAttribute('class', 'hide btn modalBtn--left');
  }

  if (startIndex >= nItems - 1) {
    rightButton[0].setAttribute('class', 'hide btn modalBtn--right');
  }
}

//TRip and Trip index are stored in image "alt" string as comma seperated "tripNum, index"
//These functions allow us to easily access them
function getTripNumber(altStr) {
  return parseInt(altStr.split(',')[0]);
}
export function getTmageIndex(altStr) {
  return parseInt(altStr.split(',')[1]);
}
window.getTmageIndex = getTmageIndex;

function updateModalInfo(modal, modalImg, trip, file_index) {
  const date_displayed = trip.dates[file_index];
  //console.log('file_index1=', file_index);
  //console.log('trip=', trip);

  // Add logging for controls visibility
  console.log('Controls check:', {
    globe: document.getElementById('globeIcon'),
    caption: document.getElementById('caption'),
    noteInput: document.getElementById('myInput'),
    saveButton: document.getElementsByClassName('btn-saveNote')[0],
  });

  let altitude_displayed = trip.altitudes[file_index];
  let latitude_displayed = trip.latatudes[file_index];
  let longtitude_displayed = trip.longitudes[file_index];

  if (altitude_displayed === null) {
    altitude_displayed = 0;
  }
  if (latitude_displayed === null) {
    latitude_displayed = 0;
  }
  if (longtitude_displayed === null) {
    longtitude_displayed = 0;
  }

  const globe_icon = document.getElementById('globeIcon');
  //console.log('latitude_displayed***', latitude_displayed);

  // Display globe if we have location data
  if (latitude_displayed === 0) {
    globe_icon.setAttribute('hidden', 'hidden');
  } else {
    globe_icon.removeAttribute('hidden');
  }

  noteInput.placeholder = trip.notes[file_index];
  noteInput.value = trip.notes[file_index];
  noteText.textContent = trip.notes[file_index];

  /* Chose which modal box to use */

  spinner.style.display = 'block';

  //Load a high resolution copy of modal photo
  const promise = getHighResolutionPhoto(trip, file_index);
  promise.then(function () {
    modalImg.src = `data:image/jpeg;base64,${trip.photoBufferLocal[file_index]}`;

    //Figure out what size box we need to contain image
    const ratioPixWdivH = trip.widths[file_index] / trip.heights[file_index];

    modal.style.display = 'block';
    console.log('window.innerWidth=', window.innerWidth);
    console.log('window.innerHeight=', window.innerHeight);

    //Set up based on how wide the window is
    // widthMaxRem is the maximum width of the photo in rem
    let widthMaxRem = 118;
    if (window.innerWidth > 1220) {
      widthMaxRem = 118;
    } else if (window.innerWidth > 900) {
      widthMaxRem = 90;
    } else if (window.innerWidth > 600) {
      widthMaxRem = 60;
    } else {
      widthMaxRem = 40;
    }

    //We need to maximize photo size based on space required for text notes at top.
    //We need to be sure entire height of phot is displayed on the screen and not pushed off the bott0m
    const PIXELS_PER_REM = 10;
    const inWindowHtRem = window.innerHeight / PIXELS_PER_REM;

    //This is straight forward in case of width greater than height
    if (ratioPixWdivH >= 1) {
      //Assume we have plenty of screen width, but restricted screen height
      console.log('ratioPixWdivH >= 1');

      //Determine virtical space required for text note
      const captionHeightPx = 60; //pixels
      console.log('captionHeightPx=', captionHeightPx);

      //Convert to rem
      const noteSpaceVRem = captionHeightPx / PIXELS_PER_REM;
      console.log('noteSpaceVRem=', noteSpaceVRem);

      //Calculate width and height of modal box
      modal.style.width = `${widthMaxRem}rem`;
      console.log('modal.style.width1=', modal.style.width);

      modal.style.height = `${widthMaxRem / ratioPixWdivH + noteSpaceVRem}rem`;
      console.log('modal.style.height1=', modal.style.height);

      //Take care of case where we are constrained by the width of the display
      console.log('widthMaxRem=', widthMaxRem);
      if (modal.style.width > `${widthMaxRem}rem`) {
        modal.style.width = `${widthMaxRem}rem`;
        modal.style.height = `${
          widthMaxRem / ratioPixWdivH + noteSpaceVRem
        }rem`;
      }
    }

    //When height is greater than width (ratioPixWdivH < 1)
    if (ratioPixWdivH < 1) {
      console.log('ratioPixWdivH < 1');

      //Total height of modal box in rem
      let totalHeightRem = widthMaxRem / ratioPixWdivH;

      //Virtical space required for text notes and icons
      const captionHeightPx = 60;
      //console.log('captionHeightPx=', captionHeightPx);

      const noteSpaceVRem = captionHeightPx / PIXELS_PER_REM;
      console.log('noteSpaceVRem=', noteSpaceVRem);

      totalHeightRem = totalHeightRem + noteSpaceVRem;
      //console.log('totalHeightRem1=', totalHeightRem);

      modal.style.width = `${widthMaxRem}rem`;
      //console.log('modal.style.width_0=', modal.style.width);

      modal.style.height = `${totalHeightRem}rem`;
      //console.log('modal.style.height_0=', modal.style.height);

      //Take care of case where we are constrained by the width of the display
      if (modal.style.width > widthMaxRem) {
        console.log('Window Width Limited');
        modal.style.width = `${widthMaxRem}rem`;
        modal.style.height = `${
          widthMaxRem * ratioPixWdivH + noteSpaceVRem
        }rem`;
      }
    }
    console.log('modal.style.width_F=', modal.style.width);
    console.log('modal.style.height_F=', modal.style.height);

    spinner.style.display = 'none';

    //Show/Hide buttons base on where we are in set
    const numPhotos = trip.dates.length;
    adjustScrollModalBtnVisability(
      scrollLeftBtns,
      scrollRightBtns,
      file_index,
      numPhotos,
    );
  });
}

//Note: Font size and width are in pixels
function measureText(pText, pFontSize, pStyle, pTextWidth) {
  //console.log('pTextWidth=', pTextWidth);

  let lDiv = document.createElement('div');

  noteText.appendChild(lDiv);

  if (pStyle != null) {
    lDiv.style = pStyle;
  }
  lDiv.style.fontSize = '' + pFontSize + 'px';
  lDiv.style.position = 'absolute';
  lDiv.style.width = `${pTextWidth}px`;
  lDiv.style.left = 150;

  lDiv.style.top = 0;

  lDiv.textContent = pText;

  //console.log('lDiv=', lDiv);

  let lResult = {
    width: lDiv.clientWidth,
    height: lDiv.clientHeight,
  };

  //Clean up
  noteText.removeChild(lDiv);
  lDiv = null;

  return lResult;
}

//Loads a higher resolution photo
async function getHighResolutionPhoto(trip, index) {
  //NOTE: loadBufferImage loads imabge into the global trip.photoBufferLocal[file_index]
  //console.log('trip.photoId[index]=', trip.photoId[index]);
  await loadBufferImage(
    trip.photoId[index],
    trip,
    index,
    LARGEIMAGERESOLUIION,
  ).then(function () {
    //console.log('trip.photoBufferLocal[index]b=', trip.photoBufferLocal[index]);
  });
}

// When the user clicks on [X], close the modal
const close_modal = document.getElementsByClassName('close')[0];
close_modal.onclick = function () {
  modal.style.display = 'none';

  //Need to re-establish active accordian element scroll buttons
  const openItem = document.getElementsByClassName('item open');
  //console.log('openItem=', openItem);

  const photos = openItem[0].getElementsByClassName('photo open');
  //console.log('photo=', photos);

  const photoAlt = photos[0].alt;
  const tripNumber = getTripNumber(photoAlt);

  const openTrip = window.tripsList[tripNumber];
  //console.log('openTrip=', openTrip);

  const rightButton = openItem[0].getElementsByClassName('btn--right');
  const leftButton = openItem[0].getElementsByClassName('btn--left');

  adjustScrollBtnVisability(
    leftButton,
    rightButton,
    openTrip.index,
    openTrip.files.length,
  );
};

// When the user clicks on [X], close the map
const modalMap = document.getElementById('mapX');
const close_modalMap = document.getElementsByClassName('closeMap')[0];
close_modalMap.onclick = function () {
  modalMap.style.display = 'none';
};

// Event listener for click on globe icon
const globes = document.querySelectorAll('.globe');
globes.forEach(globeClick);
function globeClick(item, index) {
  item.addEventListener('click', function (e) {
    //console.log('Click on globe');

    // Get the trip data for selected trip
    const trip = window.tripsList[getTripNumber(modalImg.alt)];
    // Get index of displayed photo and use to get rest of informatio
    const file_index = getTmageIndex(modalImg.alt);

    const date_displayed = trip.dates[file_index];
    const file_name = trip.files[file_index];
    let altitude_displayed = trip.altitudes[file_index];
    let latitude_displayed = trip.latatudes[file_index];
    let longtitude_displayed = trip.longitudes[file_index];

    if (altitude_displayed === null) {
      altitude_displayed = 0;
    }
    if (latitude_displayed === null) {
      latitude_displayed = 0;
    }
    if (longtitude_displayed === null) {
      longtitude_displayed = 0;
    }

    modalMap.style.display = 'block';
    myMap.invalidateSize();

    myMap.setView([latitude_displayed, longtitude_displayed], 16);

    L.marker([latitude_displayed, longtitude_displayed])
      .addTo(myMap)
      .bindPopup(`Altitude(ft): ${(altitude_displayed * 3.28084).toFixed(1)}`)
      .openPopup();

    //console.log('set view');
  });
}

// Event listeners for info button
const infos = document.querySelectorAll('.info');
infos.forEach(infoClick);
function infoClick(item, index) {
  item.addEventListener('click', function (e) {
    //console.log('Click on info', this);

    // Get the trip data for selected trip
    const trip = window.tripsList[getTripNumber(modalImg.alt)];
    //console.log('trip*', trip);

    // Get index of displayed photo and use to get rest of information
    const file_index = getTmageIndex(modalImg.alt);
    //console.log('file_index', file_index);
    const date_displayed = trip.dates[file_index];
    const file_name = trip.files[file_index];
    let altitude_displayed = trip.altitudes[file_index];
    let latitude_displayed = trip.latatudes[file_index];
    let longtitude_displayed = trip.longitudes[file_index];
    let notes_displayed = trip.notes[file_index];

    if (altitude_displayed === null) {
      altitude_displayed = 0;
    }
    if (latitude_displayed === null) {
      latitude_displayed = 0;
    }
    if (longtitude_displayed === null) {
      longtitude_displayed = 0;
    }

    //Display information on alert box
    alert(
      `Trip Name: ${
        trip.name
      } \nDate:  ${date_displayed} \nFile name: ${file_name} \nAltitude(ft): ${(
        altitude_displayed * 3.28084
      ).toFixed(1)} \nLatitude: ${latitude_displayed.toFixed(
        5,
      )} \nLongitude: ${longtitude_displayed.toFixed(5)}
      \nNotes: ${notes_displayed}`,
    );
  });
}

// Event listneres for click on note icon
const notes = document.querySelectorAll('.note');
notes.forEach(notesClick);
function notesClick(item, index) {
  item.addEventListener('click', function (e) {
    // console.log('Click on note Icon');
    noteInput.style.display = 'block';
    saveButton.style.display = 'block';
    notes[0].style.display = 'none';
    noteText.style.display = 'none';
  });
}

// Save update note in Trips database
async function patchTripNote(notesNew, tripId) {
  //console.log('notesPatchurl=', `${SERVERURL}/api/v1/trips/${tripId}`);
  await axios({
    method: 'patch',
    //url: '127.0.0.1:3000/api/v1/trips/625b43015ca26e7a9833a786',
    url: `${SERVERURL}/api/v1/trips/${tripId}`,

    data: {
      notes: notesNew,
    },
  });
}

//Save update note in Trips database
async function patchTrip(tripUpdate, tripId) {
  //console.log('tripUpdate=', tripUpdate);

  const obj = tripUpdate;
  const objStr = JSON.stringify(obj);
  //const sizeInBytes = Buffer.from(objStr, 'utf-8').length;
  //console.log('sizeInBytes=', sizeInBytes);
  await axios({
    method: 'patch',
    //url: '127.0.0.1:3000/api/v1/trips/625b43015ca26e7a9833a786',
    url: `${SERVERURL}/api/v1/trips/${tripId}`,

    data: tripUpdate,
  });
}
// Need acess from sorter
window.patchTrip = patchTrip;

// When the user clicks on the save note "Save" button
saveButton.onclick = function () {
  //Get the selected file name
  const src_str = modalImg.src;
  let index = src_str.lastIndexOf('/');
  let file_name = src_str.slice(index + 1);

  // Get the trip data for selected trip (# is saved in .alt field)
  const tripData = window.tripsList[getTripNumber(modalImg.alt)];
  // Get index of displayed photo and use to get update note
  //const file_index = tripNum.files.indexOf(file_name);
  const file_index = getTmageIndex(modalImg.alt);

  //console.log('noteInput.value=', noteInput.value);
  tripData.notes[file_index] = noteInput.value;
  //Update the note text value
  noteText.textContent = noteInput.value;

  //console.log('Click on note Save button');

  const tripId = tripData._id;
  //console.log('tripeId=', tripId);
  patchTripNote(tripData.notes, tripId);

  noteInput.style.display = 'none';
  saveButton.style.display = 'none';
  notes[0].style.display = 'block';
  noteText.style.display = 'block';
};

/////////////////////////////////////////////////////
// Code to build accordian items html based on trip data
/////////////////////////////////////////////////////
function formatDate(dateStr) {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const monthNum = dateStr.slice(5, 7);
  const month = months[monthNum - 1];
  const year = dateStr.slice(0, 4);
  return month + ' ' + year;
}

function insertAfter(newNode, referenceNode) {
  if (referenceNode.nextSibling) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
  } else {
    referenceNode.parentNode.appendChild(newNode);
  }
}

// Photo container touch events
function wirePhotoContainerTouch(container, tripsIndex) {
  let startX = 0;
  let moved = false;

  container.addEventListener('touchstart', (e) => {
    startX = e.touches[0].clientX;
    moved = false;
  });

  container.addEventListener('touchmove', (e) => {
    moved = true;
    const diff = startX - e.touches[0].clientX;
    if (Math.abs(diff) > 50) {
      e.preventDefault();
    }
  });

  container.addEventListener('touchend', (e) => {
    if (!moved) {
      return; // Was just a tap, not a swipe
    }

    const diff = startX - e.changedTouches[0].clientX;
    const hiddenBox = container.parentNode;

    if (diff > 50) {
      // Swipe left - same as right button click
      const rightButton = hiddenBox.getElementsByClassName('btn--right')[0];
      if (rightButton && !rightButton.classList.contains('hide')) {
        rightButton.click();
      }
    } else if (diff < -50) {
      // Swipe right - same as left button click
      const leftButton = hiddenBox.getElementsByClassName('btn--left')[0];
      if (leftButton && !leftButton.classList.contains('hide')) {
        leftButton.click();
      }
    }
  });
}

// Construct a new item abd ubsert ubti GTNK
const displayItem = function (photoData, tripsIndex, yearGroup) {
  const containerItems = document.querySelector('.accordion');
  //console.log(containerItems);
  //console.log('tripBuffer=', photoData.photoBufferLocal[0]);
  // <img class="footsteps" src="footsteps-outline.svg"></img>
  const newItemHTML = `<div class="item">
 
    <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" ckass="footsteps width="30" height="30" viewBox="0 0 512 512"><title>Footsteps</title><path d="M200 246.84c8.81 58.62-7.33 90.67-52.91 97.41-50.65 7.49-71.52-26.44-80.33-85.06-11.85-78.88 16-127.94 55.71-131.1 36.14-2.87 68.71 60.14 77.53 118.75zM223.65 409.53c3.13 33.28-14.86 64.34-42 69.66-27.4 5.36-58.71-16.37-65.09-49.19s17.75-34.56 47.32-40.21 55.99-20.4 59.77 19.74zM312 150.83c-8.81 58.62 7.33 90.67 52.9 97.41 50.66 7.49 71.52-26.44 80.33-85.06 11.86-78.89-16-128.22-55.7-131.1-36.4-2.64-68.71 60.13-77.53 118.75zM288.35 313.53c-3.13 33.27 14.86 64.34 42 69.66 27.4 5.36 58.71-16.37 65.09-49.19s-17.75-34.56-47.32-40.22-55.99-20.4-59.77 19.75z" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32"/></svg>
    
    <p class="destination">${photoData.name}</p>
    <p class="start-date">${formatDate(photoData.dates[0])}</p>

    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="icon"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path stroke-linecap="round" stroke-linejoin="round"
      stroke-width="2" d="M19 9l-7 7-7-7"" />
    </svg>
    <div class="hidden-box">
      <button class="hide btn btn--left">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="btn-icon"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="3"
            d="M15 19l-7-7 7-7"
          />
        </svg>
      </button>
      <button class="hide btn btn--right">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="btn-icon"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="3"
            d="M9 5l7 7-7 7"
          />
        </svg>
      </button>
      <div class="photo-container">
        <img
          class="photo open"
          <img src="data:image/jpeg;base64,${photoData.photoBufferLocal[0]}"
          alt="${tripsIndex},0"
        />
        <img
          class="photo open"
          <img src="data:image/jpeg;base64,${photoData.photoBufferLocal[1]}"
          alt="${tripsIndex},1"
        />
        <img
          class="photo open"
          <img src="data:image/jpeg;base64,${photoData.photoBufferLocal[2]}" 
          alt="${tripsIndex},2"
        />
      </div>
    </div>
    </div>`;

  // Add to bottom of groupYear accordian segment
  let newDiv = document.createElement('div');
  newDiv.innerHTML = newItemHTML;

  let referenceDiv = document.getElementById(yearGroup);
  // console.log('referenceDiv=', referenceDiv);

  // Move to the end of the end of current list of the trip items
  let lastSibling = referenceDiv;
  // console.log('lastSibling=', lastSibling);

  // Move down until you find another yearElement or the end of the list
  while (
    lastSibling.nextElementSibling &&
    lastSibling.nextElementSibling.className != 'year'
  ) {
    lastSibling = lastSibling.nextElementSibling;
  }

  // Insert the new item below
  //console.log('lastSibling=', lastSibling);
  insertAfter(newDiv, lastSibling);

  //Set up event listeners for Open/Close buttons
  const openCloseBtns = document.querySelectorAll('.icon');
  //console.log('opecloseBtns=', openCloseBtns);

  //Add click function to the last .icon in array
  wireOpenCloseBtn(
    openCloseBtns[openCloseBtns.length - 1],
    openCloseBtns.length - 1,
  );

  //Set up event listeners for scroll left  buttons
  const scrollLeftBtns = document.querySelectorAll('.btn--left');
  wireScrollLeftClick(scrollLeftBtns[scrollLeftBtns.length - 1], tripsIndex);

  //Set up event listeners for scroll right  buttons
  const scrollRightBtns = document.querySelectorAll('.btn--right');
  wireScrollRtClick(scrollRightBtns[scrollRightBtns.length - 1], tripsIndex);

  // Wire up touch events
  const photoContainer = newDiv.querySelector('.photo-container');
  if (photoContainer) {
    wirePhotoContainerTouch(photoContainer, tripsIndex);
  }

  /*Set up Event handlers for clicking on an image*/
  const photoImgs = document.querySelectorAll('.photo');
  const photoImgsLength = photoImgs.length;
  for (let i = photoImgsLength - 3; i < photoImgsLength; i++) {
    wireImageClick(photoImgs[i], i);
  }
};

// Construct a new YEAR item and insert at bottom
const displayItemYear = function (year, key) {
  const containerItems = document.querySelector('.accordion');
  //console.log(containerItems);

  const newItemHTML = `<div class="year" id=${year}>
    <p class="dummy value">"x"</p>
    <p class="group-date">${year}</p>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="iconYear"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path stroke-linecap="round" stroke-linejoin="round"
      stroke-width="2" d="M19 9l-7 7-7-7"" />
    </svg>`;

  // Add to bottom of accordion
  containerItems.insertAdjacentHTML('beforeend', newItemHTML);

  //Set up event listeners for Open/ buttons
  const openBtns = document.querySelectorAll('.iconYear');

  // //Adds the last .icon in array
  wireOpenBtnYear(openBtns[openBtns.length - 1], openBtns.length - 1);
};

async function loadTripsAndPhotos(trips, beginIndex, endIndex, yearGroup) {
  for (let index = beginIndex; index < endIndex; index++) {
    await getPhotosAfterDelay(trips[index], index, 2, yearGroup);
  }
}

async function getTripData(tripID) {
  try {
    spinner.style.display = 'none';
    const response = await fetch(`${SERVERURL}/api/v1/trips?sort=-date`);
    const data = await response.json();

    window.tripsList = data.data.trips;
    //console.log('window.tripsList=', window.tripsList);

    const groupInfo = getYearGroupInfo(window.tripsList);

    // Display all  year items
    const lGroup = groupInfo.length;
    for (let index = 0; index < lGroup; index++) {
      // Load the HTML
      displayItemYear(groupInfo[index][0]);
    }

    // Load trips below first Year
    const yearGroup = groupInfo[0][0];
    await loadTripsAndPhotos(
      window.tripsList,
      groupInfo[0][1],
      groupInfo[1][1],
      yearGroup,
    );

    // Update open button on first year item to (^)
    const openBtns = document.querySelectorAll('.iconYear');
    const item = openBtns[0];
    const childPath = item.firstElementChild;
    //console.log('childPath=', childPath);

    if (childPath.getAttributeNS(null, 'd') === 'M19 9l-7 7-7-7') {
      //Icon is down, change to up, Open accordion section
      childPath.setAttribute('d', 'M5 15l7-7 7 7');
      // svg_parent.setAttribute('class', 'item open');
    }
    // Turn off the spinner
    //spinner.style.display = 'none';
  } catch (err) {
    console.log('getTRipData ERROR', err);
  }
}

function getPhotosAfterDelay(trip, index, delay, yearGroup) {
  return new Promise((resolve) => {
    setTimeout(async function () {
      // Make space for photo data
      trip.photoBufferLocal = [];
      for (let i = 0; i < trip.photoId.length; i++) {
        trip.photoBufferLocal.push(0);
      }
      // Load first 4 photos of trip
      await Promise.all([
        loadBufferImage(trip.photoId[0], trip, 0, SMALLIMAGERESOLUTION),
        loadBufferImage(trip.photoId[1], trip, 1, SMALLIMAGERESOLUTION),
        loadBufferImage(trip.photoId[2], trip, 2, SMALLIMAGERESOLUTION),
        loadBufferImage(trip.photoId[3], trip, 3, SMALLIMAGERESOLUTION),
      ]);

      // Dynamically create an Accordion item and put it in html
      displayItem(trip, index, yearGroup);

      resolve();
    }, delay);
  });
}

// Initialization
const tripID = '';
getTripData(tripID);

export function loadBufferImage(photoID, trip, imageIndex, resolution) {
  //const containerItems = document.querySelector('.accordion');
  return new Promise(function (resolve, reject) {
    try {
      //console.log('photoID,resolution=', photoID, resolution);
      fetch(`${SERVERURL}/api/v1/photos/${photoID}?size=${resolution}`)
        .then(function (response) {
          //console.log('response', response);
          return response.json();
        })
        .then(function (data) {
          //console.log('data=', data);

          const photoImage = data.data.img.data;
          //          console.log('photoImage=', photoImage);

          // Image comes in as an arr of 8 bit decimal values
          const u8 = new Uint8Array(photoImage);
          const b64 = Buffer.from(u8).toString('base64');
          //          console.log('u8=', u8);
          //         console.log('b64=', b64);

          trip.photoBufferLocal[imageIndex] = b64;

          resolve('It worked');
        });
    } catch (err) {
      console.log('getPhotoData ERROR', err);
      reject(err);
    }
  });
}
// Make availabe to sorter non module script
window.loadBufferImage = loadBufferImage;
